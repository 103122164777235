import React from "react"

import SEO from "../components/seo"

const EventsPage = () => (
  <>
    <SEO title="Events" />
    <p>Coming soon</p>
  </>
)

export default EventsPage
